jQuery(function () {
    var $body = jQuery('body');
    $body.bind("start load ready", function () {
        jQuery("[data-replace-on-click]")
            .not('.replace-on-clicked-loaded')
            .addClass('replace-on-clicked-loaded')
            .bind("click", function(){
                var $this = jQuery(this);
                var classes = $this.attr('class');
                $this.hide();
                var $replacement = jQuery("<a></a>");
                $replacement.addClass(classes);
                $replacement.html($this.html());
                var $icon = $replacement.find('.icon');
                var bordercolor = jQuery('.btn-label').css('color');
                $icon.before('<div class="spinner" style="border-color:'+bordercolor+'; border-right-color: transparent"></div>');
                $icon.hide();
                $this.after($replacement);
            });
    });
    $body.trigger("load");
});