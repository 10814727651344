export class LoadSvgFromBackground {
    constructor(rootselector, childselector, clip, applyColor) {
        let _rootselector = ".btn";
        let _childselector = ".icon";
        let _clip = 8;
        let _applyColor = false;

        if (childselector !== undefined) {
            _childselector = childselector;
        }
        if (rootselector !== undefined) {
            _rootselector = rootselector;
        }
        if (clip !== undefined) {
            _clip = clip;
        }
        if (applyColor !== undefined) {
            _applyColor = applyColor;
        }
        jQuery(_rootselector)
            .not(".LoadSvgFromBackground")
            .addClass("LoadSvgFromBackground")
            .each(function () {
                    let $this = jQuery(this);
                    let $child = $this.find(_childselector);
                    let attr = $child.css("background-image");
                    if (!attr || attr.indexOf("url") === -1) {
                        return;
                    }
                    attr = attr.replace(/url|\(|\)|;|'|"/g, "");
                    if (attr.trim() === "") return;
                    let c = $this.css("color");
                    let _clipx = 50 - _clip;
                    $child.load(attr, [], (data) => {
                            data = data.replace('fill="silver"', "");
                            if (_clip > 0) {
                                data = data.replace('viewBox="0 0 50 50"', 'viewBox="' + _clip + ' ' + _clip + ' ' + _clipx + ' ' + _clipx + '"');
                            }
                            $child.html(data);
                            $child.addClass("icon-replaced");
                            let $svg = $child.find("svg");
                            if (_applyColor && c) {
                                $child.css("fill", c);
                                $svg.css("fill", c);
                            }
                        }
                    );
                }
            );
    }
}