if (window.localStorage.getItem('xss') !== null) {
    window.localStorage.removeItem('xss');
    window.setTimeout(function () {
        var message = "";
        message += "Es wurde ein IFrame erkannt und es bestand ein Sicherheitsrisiko. \n";
        message += "Sie wurden daher ausgeloggt. \n\n";
        message += "Die notwendigen Stellen wurden informiert und werden sich dieses Problemes annehmen.";
        jQuery("body").trigger("addmessage", [
            "error",
            message
        ]);
    }, 1000);
}

if (window.top !== window.self) {
    window.localStorage.setItem('xss', true);
    document.write = ""; // eval kill
    var warning = "<pre><br><br><br><br><br><center> Achtung, es wurde ein IFrame erkannt. <br/> <br> Die Seite wird neu geladen... </center></pre>";
    document.body.innerHTML = warning;
    window.self.onload = function () {
        document.body.innerHTML = warning;
    };
    setTimeout(function () {
        document.body.innerHTML = warning;
        window.top.location = "/xss/iframe";
    }, 1);
}
