jQuery(function () {
    var $body = jQuery('body');
    $body.bind("start load ready", function () {
        jQuery("[data-search-children]").not(".dsc-loaded").addClass("dsc-loaded").each(function () {
            var $root = jQuery(this);
            var selector = $root.data("search-children");
            var $children = $root.find(selector);
            var $input = jQuery('<input type="text" placeholder="Suche" class="dsc-input">');
            var timer1 = null;
            var timer2 = null;
            var timer3 = null;
            $input.hide();
            $input.prependTo($root);
            $input.bind("input", function () {
                window.clearTimeout(timer1);
                window.clearTimeout(timer2);
                window.clearTimeout(timer3);
                let $this = jQuery(this);
                $this.removeClass("waiting");
                $this.removeClass("loading");

                timer1 = window.setTimeout(() => {
                    $this.addClass("waiting");
                }, 500);
                timer2 = window.setTimeout(() => {
                    $this.removeClass("waiting");
                    $this.addClass("loading");
                }, 800);

                timer3 = window.setTimeout(() => {
                    var term = $this.val().trim().toLowerCase();
                    $children.each(function () {
                        var $child = jQuery(this);
                        if ($child.text().toLowerCase().includes(term)) {
                            $child.not(":visible").show();
                        } else {
                            $child.not(":hidden").hide();
                        }
                    });
                    $this.removeClass("loading");
                }, 1000);
            });
            $input.bind("dsc-escape", function (e) {
                if ($input.val() !== "") {
                    $input.val("");
                    $input.trigger("input");
                } else {
                    $input.hide();
                    $children.show();
                }

            });
            $root.bind("dsc-refresh", function (e) {
                $children = $root.find(selector);
            });
            $root.bind("mouseenter mousemove", function () {
                if ($input.is(":hidden")) {
                    $input.show().focus();
                    $input.trigger("input");
                }
            });
            $body.bind("escape", function () {
                $input.trigger("dsc-escape");
            });
        });
    });
    $body.trigger("load");
});
