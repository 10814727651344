jQuery("body").on("start ready load", function () {
    setKachelFolded();
});

export function setKachelFolded(){
    jQuery(".kachel-folded")
        .not(".kachelfolded-loaded")
        .addClass("kachelfolded-loaded")
        .each(function () {
            var $kachel = jQuery(this);
            $kachel.find('.header').on("click", () => {
                $kachel.toggleClass("kachel-folded");
                $kachel.toggleClass("kachel-folded-up");
            });
        });
}
