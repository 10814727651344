jQuery(
    function () {
        jQuery(".scroll-to-top").bind("click", function (e) {
            e.preventDefault();
            jQuery("html, body").stop().animate({scrollTop: 0}, 500, () => {
                jQuery(this).blur();
            });
        });
    }
);
