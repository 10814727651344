// Der Alias @op ist definiert in webpack.config.js
import '@op/index.js';
import '@op/js/lib/replace-on-click.js';

require('@op/images/logo.png');
require('@op/images/BFP_Logo.svg');
require('@op/images/startpage_image_2.jpg');
require('@op/images/devtoolsErgebnisMarkterkundungKarte.png');
require('@op/images/aconium-logo-BFP.png');

