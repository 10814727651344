import "./noiframe";
import "./_scroll-to-top";
import "./_scroll-to-invalid";
import "./lib/btn-group-switchable";
import "./lib/data-search-children";
import "./lib/kachel-folded";
import {LoadSvgFromBackground} from "./_LoadSvgFromBackground";

jQuery(function () {
    jQuery("body").on("start load ready", function () {
        new LoadSvgFromBackground("#mainmenu .btn", "span.icon", 0);
        new LoadSvgFromBackground("#breadcrumb .btn", "span.icon", 8);
        new LoadSvgFromBackground("#content .btn", "span.icon", 8);
        new LoadSvgFromBackground("#userinfo .btn", "span.icon", 8);
        new LoadSvgFromBackground("#content .text-with-icon, #content .input-group-text", "span.icon", 8);
        new LoadSvgFromBackground("#page .row .text-left", "span.icon", 8);
        new LoadSvgFromBackground(".badge", "span.icon", 8);
    }).trigger("start");
});
